.white-popup {
position: relative;
background: #FFF;
padding: 45px;
width: auto;
max-width: 700px;
margin: 20px auto;
box-shadow: 10px 10px 60px #555;
border: #0055b8 10px solid;
@media screen and (max-width:768px) {
    max-width: 500px;
    padding: 30px
}
}