footer .gform_wrapper {
  form {
    width: 100%;
    margin: 0 auto;
    max-width: 425px;
    color: #fff;
    label {
      color: #fff;
    }
    .gfield_required {
      display: none;
    }
    input[type="text"] {
      background: rgba(255,255,255,0.7);
      border: none;
      box-shadow: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }
    .ginput_container_consent {
      display: flex;
      align-items: flex-start;
      justify-content: baseline;
      label {
        margin-left: 1rem;
        font-size: 12px;
        line-height: 18px;
      }
    }
    .gform_footer {
      input[type="submit"] {
        @extend .btn; 
        @extend .header-button;
        background: none;
        box-shadow: none;
        border: none;
        cursor: pointer;
        position: relative;
        left: unset;
        right: unset;
        bottom: unset;
        text-align: center;
        display: block;
        transform: unset;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .validation_error {
      color: $gold;
    }
  }
}

.gform_wrapper form:not(#gform_1) {
  input, textarea {
    border: solid 1px $blue;
  }
  .gform_footer {
    text-align: center;
    .gform_button {
      background: none;
      box-shadow: none;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      display: inline-block;
      border: none;
      border-radius: 0px;
      @extend .btn;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5OCIgaGVpZ2h0PSI4NiIgdmlld0JveD0iMCAwIDk4IDg2Ij48cmVjdCB3aWR0aD0iOTgiIGhlaWdodD0iODYiIGZpbGw9IiNmOGY0ZDQiLz48L3N2Zz4=');
      background-repeat: repeat;
      background-size: 12px 100%;
      background-position: center 4px;
    }
  }
}

.gform_wrapper {
  .gfield select {
    padding: 8px 4px!important;
    border-radius: 0px;
    border-color: $blue;
  }
  .ginput_container.ginput_container_date .ginput_container_date {
    margin-top: 0px!important;
  }
  li[id*="field_4"] {
    .ginput_container.ginput_container_consent {
      margin-top: 4px;
      margin-bottom: 24px;
    }
    .gfield_description.gfield_consent_description {
      font-size: 0.875rem;
      max-height: unset;
    }
  }
}

.white-text .gform_confirmation_message {
  color: #fff;
}