/* Margins */

.mt-10 {
  margin-top: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mv-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

.mt-40 {
  margin-top: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mv-40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mt-60 {
  margin-top: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mv-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.mv-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.mt-120 {
  margin-top: 120px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mv-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.mt-160 {
  margin-top: 160px;
}

.mb-160 {
  margin-bottom: 160px;
}

.mv-160 {
  margin-top: 160px;
  margin-bottom: 160px;
}

/* Paddings */

.pt-40 {
  padding-top: 40px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pv-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pv-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pv-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pv-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.pt-160 {
  padding-top: 160px;
}

.pb-160 {
  padding-bottom: 160px;
}

.pv-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}
.drink-list {
  line-height: 1.2em;
  li {
    margin-bottom: 5px
  }
}

@media screen and (max-width: 768px) {
  .pt-160 {
    padding-top: 80px;
  }
  .pb-160 {
    padding-bottom: 80px;
  }
  .pv-160 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}