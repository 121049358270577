#calendar-container {
  font-family: $europa;
  max-width: 980px;
  margin: 0 auto
}
.mec-calendar-topsec {
display:flex;
flex-direction: row;
}
.mec-calendar-side {
display: flex;
width: 70%
}
.mec-calendar dt {
  line-height: revert !important;
  vertical-align: middle
}
.mec-calendar .mec-has-event a {
  display: inline !important;
}
.mec-calendar-events-side {
display: flex;
width: 30%
}
.mec-calendar .mec-has-event:after {
  bottom: 40px
}

.mec-event-meta dd a {
  font-size: 0.65rem
}
@media screen and (max-width: 571px) {
  .mec-events-meta-group-countdown {
    margin-left: 0 !important
  }
}