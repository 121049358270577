img {
  max-width: 100%;
}

.gold-dots {
  width: 133px;
}

.icon {
  height: 100px;
  width: auto;
}

.social-icon {
  width: 32px;
  height: auto;
}

#map-image {
  width: 90%;
  max-width: 750px;
}

.img-wrap {
  position: relative;
  &.box-highlight {
    img, video {
      border: solid 5px $white;
      position: relative;
      z-index: 1;
    }
    &::before {
      content: "";
      z-index: 0;
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      height: 100%;
      width: 100%;
      background-color: $lightGold;
      transform: translate(30px, 30px);
      @media screen and (max-width: 576px) {
        transform: translate(18px, 18px);
      }
    }
  }
}