.accordion-section {
  #accordion-content {
    .accordion-item {
      display: block;
      padding-left: 5rem;
      position: relative;
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-top: 1px solid #ddd;
      &:last-of-type {
        border-bottom: solid 1px #ddd;
      }
      aside {
        position: absolute;
        left: 1rem;
        top: 10px;
        transform: scale(0.85);
        padding: 1rem;
        border: solid 3px $blue;
        border-radius: 100px;
        width: 24px;
        cursor: pointer;
        height: 24px;
        transition: border 0.15s ease-in-out;
        svg {
          position: absolute;
          left: 0;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          margin: 0 auto;
          path {
            transition: 0.15s all ease-in-out;
          }
        }
      }
      &--title {
        position: relative;
        display: inline-block;
        cursor: pointer;
        user-select: none;
      }
      &--content {
        display: block;
        overflow: hidden;
        height: 100%;
        max-height: 0px;
        transition: max-height 0.35s ease-out;
        will-change: max-height;
      }
      &.open {
        .accordion-item--content {
          max-height: 20em;
          transition: 0.75s max-height ease-out;
        }
        aside {
          border-color: $gold;
          svg {
            transform: rotate(45deg) translate(-37%, -37%);
            path {
              stroke: $gold;
            }
          }
        }
      }
    }
  }
}