.link-card {
  border-radius: 1rem;
  .inner {
    padding: 0.5rem;
    background-color: #fff;
    text-align: center;
    img:not(.gold-dots) {
      width: 100%;
      height: auto;
    }
    .main-image-wrap {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;
      > img {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        margin: 0 auto;
      }
    }
  }
  &.fleet-card {
    .img-wrap {
      position: relative;
      width: 100%;
      display: block;
      height: 0;
      overflow: hidden;
      padding-bottom: 65%;
      > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}