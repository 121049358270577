.image-gallery-section {
  width: 100%;
  margin-left: -16px;
  margin-right: -16px;
  position: relative;
  .swiper-container {
    z-index: 1;
    width: calc(100% + 32px);
    .swiper-slide {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  > .container-fluid {
    width: calc(100% + 32px);
  }
  header {
    position: relative;
    z-index: 1;
  }
  .swiper-outer {
    position: relative;
  }
  .highlight-band {
    z-index: 0;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -1rem;
    margin: 0 auto;
    transform: translateY(40px);
    width: 85%;
    opacity: 0;
    height: 200px;
    transition: opacity 0.5s linear;
    background-color: $lightGold;
    @media screen and (max-width: 767px) {
      display: none;
    }
    &.loaded {
      opacity: 1;
    }
    &.has-link {
      bottom: 6rem;
    }
  }
  .highlight-band + .container {
    position: relative;
    margin-top: 4rem;
  }
  @media screen and (max-width: 567px) {
    margin-left: -8px;
    margin-right: -8px;
    .swiper-container, .container-fluid {
      width: calc(100% + 16px);
    }
    .highlight-band + .container {
      margin-top: 2rem;
    }
  }
}