body > #container {
  padding: 16px;
  background-color: $white;
  background-image: url('/wp-content/uploads/images/white-bg.jpg');
  background-repeat: repeat-y;
  background-size: 1800px auto;
  background-position: center;
  @media screen and (max-width: 567px) {
    padding: 8px;
  }
}
#hero-inner {
  overflow-x: hidden; /* For Opera */
    -webkit-box-shadow:
    inset #657886 0 0 0 17px;
    -moz-box-shadow:
    inset #657886 0 0 0 17px;
    box-shadow:
    inset #657886 0 0 0 17px;
    z-index: 1000;
}
@media screen and (max-width: 768px) {
  #hero-inner {
  overflow-x: hidden; /* For Opera */
    -webkit-box-shadow:
    inset #657886 0 0 0 10px;
    -moz-box-shadow:
    inset #657886 0 0 0 10px;
    box-shadow:
    inset #657886 0 0 0 10px;
    z-index: 1000;
}
  .mv-160 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mt-160 {
    margin-top: 80px;
  }
  .mb-160 {
    margin-bottom: 80px;
  }
}

.page-template-page-flex {
  [data-module-index="1"]:not(.hero_banner):not(.pv-160) {
    margin-top: 160px;
  }
}

.home.page-template-page-flex {
  [data-module-index="1"].hero_banner {
    .mb-120 {
      @media screen and (max-width: 767px) {
        margin-bottom: 60px;
      }
    }
    @media screen and (max-width: 767px) {
      .mb-120 {
        margin-bottom: 60px;
      }
      #hero-inner > .container.text-center {
        margin-top: -4rem;
        #hero-logo {
          margin-bottom: 4px;
        }
      }
    }
  }
}

.single {
  .divider {
    height: 1px;
    border-top: 1px solid #e2e2e2;
    margin-bottom: 1rem;
    margin-left: 0;
    margin-right: 0;
  }
}

/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;

  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}
/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}