#insta-feed {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: 120px;
  .row {
    .col-6:nth-child(even) {
      transform: translateY(-2rem);
    }
    .col-6:nth-child(odd) {
      transform: translateY(2rem);
    }
    img:hover {
      opacity: 0.85;
    }
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 87.5%;
    height: 87.5%;
    background-color: $lightGold;
    z-index: 0;
  }
}