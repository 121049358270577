#hero {
  height: calc(100vh - 32px);
  @media screen and (max-width: 768px) {
    height: calc(100vh - 16px);
  }
}
.hero_banner {
  min-height: 500px;
}
#hero-inner {
  height: 100%;
  width: 100%;
  background-image: url(/wp-content/uploads/images/splash-cover.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

#hero-logo {
  width: 80%;
  max-width: 630px;
  @media screen and (min-width: 768px) {
    margin-bottom: 32px;
  }
}
@media screen and (max-height: 500px) {
  #hero-logo {
    position: absolute;
    top: 60px;
    left: 27%;
    width: 46%;
  }
  #hero-inner {
    h2 {
      font-size: 2.25rem;
    }
  };
}
@media screen and (max-height: 350px) {
   #hero-inner {
    h2 {
      font-size: 1.5rem;
      margin-bottom: 0
    }
    p {
      font-size: 0.7rem
    }
  };
}

.single-happenings {
  header.video-header {
    height: 50vh;
    min-height: 500px;
    #hero-inner {
      position: relative;
      > * {
        z-index: 1;
        color: #fff;
      }
      h3 {
        font-weight: 400;
      }
      &::before {
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        z-index: 0;
        background-color: rgba(0,0,0,0.3);
      }
    }
  }
}