#main-header {
  position: absolute;
  top: 0;
  padding: 1rem 2.5rem;
  margin-top: 3rem;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 9999;
  justify-content: space-between;
  a[rel="home"] {
    display: block;
    width: 140px;
    transition: 0.5s ease-in cubic-bezier(0.645, 0.045, 0.355, 1);
    justify-self: flex-start;
    @media screen and (max-width: 992px) {
      width: 90px;
    }
    .logo-light {
      display: none;
    }
  }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    li {
      display: inline-block;
    }
  }
  #main-navigation {
    justify-self: center;
    width: calc(100% - 250px);
    ul {
      text-align: center;
      display: flex;
      justify-content: space-evenly;
      li a {
        @include transition($defaultTransition);
        text-transform: uppercase;
        text-decoration: none;
        &:hover {
          text-decoration: none;
          color: $gold;
        }
      }
    }
    @media screen and (max-width: 992px) {
      width: calc(100% - 200px);
      ul li a {
        font-size: 0.75rem;
      }
    }
  }
  .header-social {
    width: 100px;
    text-align: right;
    li {
      margin-left: 1rem;
      a[aria-label*="Instagram"] svg {
        transform: translateY(1px);
      }
      a svg {
        width: 1.5rem;
        transition: filter 0.2 ease-in-out;
        @media screen and (max-width: 900px) {
          width: 1.25rem;
          margin-top: 0.25rem;
        }
        path {
          transition: all 0.2s ease-in-out;
        }
        &:hover path {
          fill: $gold!important;
        }
      }
    }
  }
  &.light-header {
    @media screen and (min-width: 768px) {
      a[rel="home"] .logo-light {
        display: block;
      }
      a[rel="home"] .logo-dark {
        display: none;
      }
      .header-social li a svg path {
        fill: #fff;
      }
      #main-navigation ul li a {
        color: $white;
        &:hover {
          color: $gold;
        }
      }
    }
  }
  &.sticky {
    position: fixed;
    left: 0;
    right: 0;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    top: 0;
    margin-top: 0;
    z-index: 3;
    background-color: #fff;
    box-shadow: 0px 0px 8px -2px rgba(0,0,0,0.2);
    animation: stickyheader 0.4s forwards 1 ease-in-out;
  }
  &.mobile-menu > a[rel="home"] {
    transform: translateX(-300px);
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    margin-top: 0;
    background-color: #fff;
    transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    &.mobile-menu {
      background: transparent;
    }
  }
  // position: absolute;
  // left: 24px;
  // right: 24px;
  // top: 40px;
  // text-align: center;
  // a {
  //   @include transition($defaultTransition);
  //   color: $white;
  //   text-transform: uppercase;
  //   text-decoration: none;
  //   margin-left: 16px;
  //   margin-right: 16px;
  //   &:hover {
  //     text-decoration: none;
  //     color: $gold;
  //   }
  // }
  // @media screen and (max-width: 768px) {
  //   top: 16px;
  // }
}

#mobile-menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 999;
  background-color: $white;
  height: 100%;
  transition: transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transform: translateX(calc(100% + 30px));
  .mobile-menu-header {
    background-color: $blue;
    padding: 5rem 3rem 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 250px;
    }
  }
  &.open-menu {
   transform: translateX(0); 
  }
  .mobile-menu-body {
    padding-bottom: 12rem;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    ul {
      list-style: none;
      padding: 0;
      margin: 2rem auto;
      li {
        display: block;
        text-align: center;
        &.current-menu-item {
          margin-bottom: 0.25rem;
        }
        a {
          font-family: $raleway;
          font-weight: 900;
          text-transform: uppercase;
          color: $blue;
          font-size: 1.5rem;
          position: relative;
          &[aria-current] {
            padding-bottom: 14px;
            color: $gold;
            &::after {
              background: url('/wp-content/uploads/images/gold-dots.svg') no-repeat;
              background-position: center 0px;
              content: "";
              width: 85%;
              height: 12px;
              bottom: 0;
              background-size: 140px auto;
              left: 0;
              right: 0;
              margin: 0 auto;
              position: absolute;
            }                        
          }
        }
      }
    }
    .header-social {
      li {
        display: inline-block;
        margin: 0 4px!important;
      }
    }
  }
}

#nav-toggle {
  position: relative;
  height: 18px;
  right: 1.75rem;
  span {
    width: 28px;
    height: 3px;
    background-color: $blue;
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    transition: 0.2s all ease-in-out;
    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 7px;
    }
    &:nth-child(3) {
      top: 14px;
    }
  }
  &.open {
    span {
      background-color: $gold;
    }
    span:nth-child(1) {
      transform: rotate(45deg) translate(5px, 5px);
    }
    span:nth-child(2) {
      transform: scaleX(0);
    }
    span:nth-child(3) {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }
}

// Nav dropdowns
#main-navigation li.menu-item-has-children {
  position: relative;
  > .sub-menu {
    position: absolute;
    display: block!important;
    left: -1rem;
    width: 100%;
    min-width: 200px;
    overflow: hidden;
    background: #fff;
    text-align: left!important;
    max-height: 0px;
    box-shadow: 0px 3px 8px -5px rgba(0,0,0,0.2);
    transition: max-height 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86);
    a {
      padding: 0 1rem;
      display: block;
      color: $blue!important;
      &:hover {
        color: $gold!important;
      }
    }
    li {
      display: block;
    }
    li:first-child a {
      padding-top: 1rem;
    }
    li:last-child a {
      padding-bottom: 1rem;
    }
  }
  &:hover > .sub-menu {
    max-height: 15em;
  }
}

#mobile-menu .sub-menu {
  margin: 2px auto!important;
}

@keyframes stickyheader {
  0% {
    transform: translateY(-150px);
  }
  100% {
    transform: translateY(0);
  }
}

body[style*="padding-top: 46"] #main-header.sticky {
  padding-top: 52px;
}

@media screen and (max-width: 767px) {
  body[style*="padding-top: 46"] #main-header {
    padding-top: 60px;
  }
}