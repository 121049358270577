.menu-row {
  margin-bottom: 32px;

  h4 {
    margin-bottom: 0.5rem;
  }
  p {
    margin-bottom: 0;
  }
}

#drink-callout {
  width: 500px;
}

.drink-list {
  padding: 0;
  list-style: none;
}

.drink-title {
  font-size: 2.75rem;
  line-height: 1em;
  @media screen and (max-width: 576px) {
    font-size: 1.5rem;
  }
}
