footer.site-footer {
  background-color: $darkBlue;
}

#footer-logo {
  width: 60%;
  max-width: 350px;
}

#footer-bottom {
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 2rem;
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    li {
      display: block;
      color: #fff;
      font-size: 1.125rem;
      text-transform: uppercase;
      margin-bottom: 4px;
      a {
        color: #fff;
      }
    }
  }
}