$raleway: raleway, sans-serif;
$europa: europa, sans-serif;

body {
  font-size: 16px;
}

body,
p,
div,
a {
  font-family: $europa;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  color: $blue;
  font-size: 1rem;
  line-height: 1.75em;
}

span {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
.header-style {
  font-family: $raleway;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 900;
  color: $blue;
  line-height: 1.25em;
  letter-spacing: 0.1rem;
  margin-bottom: 0.75rem;
}

h2,
.h2-size {
  font-size: 3.75rem;
  line-height: 1em;
  @media screen and (min-width: 577px) and (max-width: 1900px) {
    font-size: 3.25vw;
  }
}

.rope-headline {
  h2 {
    @media screen and (min-width: 577px) and (max-width: 1900px) {
      font-size: 3.25vw;
    }
  }
  h3 {
    @media screen and (min-width: 577px) and (max-width: 1900px) {
      font-size: 2.375vw;
    }
  }
  .prehead {
    @media screen and (min-width: 577px) and (max-width: 1900px) {
      font-size: 1.5vw;
    }
  }
}

h3 {
  font-size: 2.75rem;
}

h4, .h4-size {
  font-size: 1.5rem;
}

p {
  &.big-text {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 576px) {
  h2,
  .h2-size {
    font-size: 2rem;
    line-height: 1em;
  }
  h3,
  .h3-size {
    font-size: 1.5rem;
    line-height: 1em;
  }

  h4,
  .h4-size {
    font-size: 1.25rem;
    line-height: 1em;
  }
}

.gold-text {
  color: $gold;
}

.white-text {
  color: $white;
}

.blue-text {
  color: $blue;
}

.regular-weight {
  font-weight: 400;
}

.black-weight {
  font-weight: 900;
}

// Special type styles

.prehead {
  @extend .header-style;
  font-size: 2rem;
  line-height: 2.25rem;
  margin-bottom: 0.25rem;
  letter-spacing: 0;
  &._drink-prehead {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1em;
  }
}

.price {
  font-weight: 400;
}

.item-details {
  font-style: italic;
}

@media screen and (max-width: 576px) {
  .prehead {
    font-size: 1rem;
    line-height: 1.25em;
  }
}

.lowercase {
  text-transform: initial;
}

.callout-sub {
  font-family: $raleway;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.25rem;
  @media screen and (max-width: 768px) {
    font-size: 1rem;
    line-height: 1.25rem;
  }
}

// Links
.btn {
  @extend .header-style;
  font-size: 1rem;
  text-decoration: none;
  position: relative;
  z-index: 1;
  @include transition($defaultTransition);
  &.highlight-btn {
    &:after {
      width: calc(100% + 16px);
      transform: translate(-8px, -12px);
    }
  }
  &:hover {
    text-decoration: none;
    color: $lightBlue;
  }

  &.header-button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    color: $white;
    &:after {
      content: "";
      height: 3px;
      width: 100%;
      background-color: $gold;
    }
    &:hover {
      color: $gold
    }
    @media screen and (max-width: 768px) {
      bottom: 32px;
    }
    @media screen and (max-height: 350px) {
    bottom: 0px
    }
  }
  &:not(.header-button):not(.gform_button) {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5OCIgaGVpZ2h0PSI4NiIgdmlld0JveD0iMCAwIDk4IDg2Ij48cmVjdCB3aWR0aD0iOTgiIGhlaWdodD0iODYiIGZpbGw9IiNmOGY0ZDQiLz48L3N2Zz4=');
    background-repeat: repeat;
    background-size: 12px 100%;
    background-position: center 4px;
  }
}

a {
  @include transition($defaultTransition);
  &.contact-link {
    text-decoration: none;
    color: $white;
    text-transform: uppercase;
    font-size: 1.25rem;
    @media screen and (max-width: 768px) {
      font-size: 1rem;
    }
    &:hover {
      color: $gold;
      text-decoration: none;
    }
  }
}

.divider {
  color: $white;
  text-transform: uppercase;
  font-size: 1.25rem;
  margin: 0 1rem;
}

.hours-block {
  list-style: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  max-width: 300px;
  width: 100%;
  > li {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    span {
      display: inline-block;
      width: 50%;
      &:last-child {
        text-align: right;
      }
    }
  }
}

.pricing-table {
  background-color: #fff;
  border: solid 1px $blue;
  width: 100%;
  td, th {
    border-bottom: solid 1px $blue;
    padding: 1rem 2%;
    text-align: center;
    width: 33.33%;
    vertical-align: middle;
  }
  th {
    background-color: $blue;
    color: $white;
    border-color: #fff!important;
    @extend h4;
    @media screen and (max-width: 576px) {
      font-size: 15px;
    }
    span {
      @extend p;
      color: #fff;
      display: block;
      font-size: 14px;
    }
  }
  tr > td, tr > th {
    border-right: solid 1px $blue;
    &:last-child {
      border-right: none;
    }
  }
  td[colspan="3"] {
    font-size: 14px;
    color: #000;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }
}

#contact-hours {
  p.mt-10.mb-0 {
    width :100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  table {
    width: 100%;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    tr > td:last-child {
      text-align: right;
    }
  }
}